import client from './index'

import { ApiResponse } from '@/models/api'
import { Setting } from '@/models/setting'

export default {
  getAppSettings() {
    return client.get<ApiResponse<Setting[]>>('/app-settings')
  },

  updateAppSetting(id: number, value: string) {
    return client.put<ApiResponse<Setting>>(`/app-settings/${id}`, { value })
  },
  getBrandListModelCSV() {
    return client.get<ApiResponse<Blob>>(
      '/insurance/get-brand-models-csv-file',
      {
        responseType: 'blob',
      }
    )
  },
  getBrandListModelXLSX() {
    return client.get<ApiResponse<Blob>>(
      '/insurance/get-brand-models-xlsx-file',
      {
        responseType: 'blob',
      }
    )
  },
}
