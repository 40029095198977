import client from './index'

export interface CqDatum {
    model_id: number
    model_make_id: string
    model_name: string
    model_trim: string
    model_year: number
}

export interface ApiResponse<T> {
    message: string
    status: string
    code: number
    data: T
}

export interface ApiError {
    message: string
    status: string
    code: number
    errors?: any
}

interface QueryParams {
    sortBy: string
    sortOrder: string
    page?: number
    perPage?: number
    model_id?: string
    model_make_id?: string
    model_name?: string
    model_trim?: string
    model_year?: string
}

export const createCqDatum = async (data: Omit<CqDatum, 'model_id'>) => {
    return client.post<ApiResponse<CqDatum>>('/cq-data', data)
}

export const getCqData = async (params: QueryParams) => {
    return client.get('/cq-data', { params })
}

export const updateCqDatum = async (
    model_id: number,
    data: Partial<CqDatum>
) => {
    return client.put<ApiResponse<CqDatum>>(`/cq-data/${model_id}`, data)
}

export const deleteCqDatum = async (model_id: number) => {
    return client.delete<ApiResponse<null>>(`/cq-data/${model_id}`)
}
