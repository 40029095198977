import { Setting, SettingGroup, SettingKey } from '@/models/setting'
import { ListModule } from '@/models/store'

import { getInitialListModuleState } from '@/helpers/store-state'

interface SettingsModule extends ListModule<Setting> {
  updating: {
    error: null | string
    loadingKeys: SettingKey[]
    successKeys: SettingKey[]
  }
}

export interface State {
  settingsModule: SettingsModule
}

export type SettingsByGroup = { [key in SettingGroup]?: Setting[] }

export default (): State => ({
  settingsModule: {
    ...getInitialListModuleState(),
    updating: { error: null, successKeys: [], loadingKeys: [] },
  },
})
